<template>
  <el-dialog
      :title="isEdit ? '编辑用户':'新增用户'"
      width="1000px"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="model_activated"
      @closed="$emit('update:activated', false)"
  >
    <div class="idCard-brief">
      <span >*</span> &nbsp;
      <span >身份证号适用于防止用户数据重复，身份唯一性校验</span>
    </div>
    <el-form ref='form' label-width="100px" size="mini" :inline="true" :rules="rules" :model="form">
      <el-form-item label="姓名:" prop="name">
        <el-input v-model="form.name" clearable placeholder="请输入姓名"/>
      </el-form-item>
      <el-form-item label="工号:" prop="haierCode">
        <el-input v-model="form.haierCode" clearable placeholder="请输入工号"/>
      </el-form-item>
      <el-form-item label="直线:">
        <el-select v-model="form.superiorId" filterable>
           <el-option v-for="item in userList" :value="item.id" :label="item.name">
             <span style="float: left;color: #8492a6; font-size: 13px">{{ item.haierCode }}</span>
             <span style="float: right;">{{ item.name }}</span>
           </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="部门:" prop="deptId" >
        <tree-select
            style="width: 200px"
            noOptionsText="暂无数据"
            :options="deptList"
            v-model="form.deptId"
            :disable-branch-nodes="true"
            :show-count="false"
            placeholder="请选择部门"
        />
      </el-form-item>
       <el-form-item label="用户类型:" prop="inFlag">
         <el-select v-model="form.inFlag" size="mini" clearable placeholder="请选择用户类型">
          <el-option :value="0" label="内部用户"></el-option>
          <el-option :value="1" label="外部用户"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号:" prop="phone">
        <el-input v-model="form.phone" clearable placeholder="请输入手机号"/>
      </el-form-item>
      <el-form-item label="身份证号:" prop="idCardNo">
        <el-input v-model="form.idCardNo" clearable placeholder="请输入身份证号"/>
      </el-form-item>
      <el-form-item label="性別:" prop="sex">
        <el-select v-model="form.sex" size="mini" clearable placeholder="请选择性別">
          <el-option :value="0" label="女"></el-option>
          <el-option :value="1" label="男"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="邮箱:">
        <el-input v-model="form.email" clearable placeholder="请输入邮箱"/>
      </el-form-item>
      <el-form-item label="密码:" v-if="!isEdit">
        <el-input v-model="password" type="password" clearable placeholder="默认密码为123456"/>
      </el-form-item>
      <el-row style="margin-top: 15px;">
        <div class="blockTitle">银行档案</div>
        <el-button type="primary" size="mini" style="float: right;margin-bottom: 10px;margin-right: 20px;" @click="addBank">添加</el-button>
        <el-table
            border
            size="mini"
            :data="form.bankList"
            style="width: 100%; min-height: 50px"
            :header-cell-style="{background: '#f6f6f6'}">
          <el-table-column
              prop="bankName"
              label="开户行名称"
              align="center">
            <template #default="scope">
              <el-form-item
                  label-width="0"
                  :prop="`bankList.${scope.$index}.bankName`"
                  :rules="[{required:true,message:'请输入支行名称，如海尔路支行'}]">
                <el-select
                    size="mini"
                    v-model="scope.row.bankName"
                    style="width:100%"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入支行名称，如海尔路支行"
                    :remote-method="findEnterpriseList"
                    @change="handleSelect(scope.row,scope.$index,)">
                  <el-option
                      v-for="item in selectEnterpriseList"
                      :key="item.bankName"
                      :label="item.bankName"
                      :value="item.bankName">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="bankCode"
              label="开户行代码"
              align="center">
            <template #default="scope">
              <el-form-item
                  label-width="0">
<!--                <el-input readonly size="mini" v-model="scope.row.bankCode"-->
<!--                          placeholder="请正确填写开户行名称会自动带出开户行代码"></el-input>-->
                <el-select
                    size="mini"
                    v-model="scope.row.bankCode"
                    style="width:100%"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入开户行代码"
                    :remote-method="findEnterpriseCodeList"
                    @change="handleCodeSelect(scope.row,scope.$index,2)">
                  <el-option
                      v-for="item in selectEnterpriseCodeList"
                      :key="item.bankBramchCode"
                      :label="item.bankBramchCode"
                      :value="item.bankBramchCode">
                    <span style="float: left">{{ item.bankBramchCode }}</span>
                    <span style="float: right; color: #8492a6; font-size: 12px">{{ item.bankName }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="bankCardNumber"
              label="银行卡号"
              align="center">
            <template #default="scope">
              <el-form-item
                  label-width="0"
                  :prop="`bankList.${scope.$index}.bankCardNumber`"
                  :rules="[{required:true,validator: validatorNumber}]">
                <el-input size="mini" v-model="scope.row.bankCardNumber" placeholder="请输入银行卡号"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="bank"
              label="总行"
              align="center">
            <template #default="scope">
              <el-form-item
                  label-width="0"
                  :prop="`bankList.${scope.$index}.bankCategory`"
                  :rules="[{required:true,message:'请选择'}]">
                <el-select v-model="scope.row.bankCategory" size="mini" filterable clearable
                           @change="bankChange($event,scope.$index)">
                  <el-option v-for="item in bankTolList" :key="item.banktyp"  :label="item.banknam"
                             :value="item.banktyp"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="是否默认" align="center"  prop="isDefault">
            <template #default="scope">
              <el-form-item label-width="0">
                <!--                  <el-checkbox v-model="scope.row.isDefault" size="mini"></el-checkbox>-->
                <!--                  <el-radio v-model="radio" ></el-radio>-->
                <input type="radio" name="def" :checked="scope.row.isDefault"
                       @change="defaultChange(scope.$index, scope.row)">
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template #default="scope">
              <el-button type="text" size="mini" @click="delBankItem(scope.row,scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click="model_activated = false">取 消</el-button>
      <el-button type="primary" size="mini" @click="save">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import service from '@/api/department'
import companyService from '@/api/company'
import TreeSelect from '@riophae/vue-treeselect'
export default {
  name: "addOrEditUserDialog",
  components: {TreeSelect,},
  props: {
    isEdit: {type: Boolean, default: false},
    row: {type: Object},
    deptList: {type: Array, default: []}
  },
  data() {
    return {
      selectEnterpriseList: [],
      selectEnterpriseCodeList: [],
      model_activated: true,
      userList: [],
      form: {
        name: '',
        deptId: null,
        phone: '',
        idCardNo: '',
        email: '',
        bankList: [],
        haierCode: '',//员工号
        superiorId: '',//直线
        sex: '',
        inFlag: '',//用户类型0 内部人员 1 外部人员
      },
      password: '123456',
      bankTolList: [],
      rules: {
        name: [{required: true, message: '请输入姓名'}],
        phone: [{required: true, message: '请输入手机号'}],
        idCardNo: [{required: true, message: '请输入身份证号'}],
        // haierCode: [{required: true, message: '请输入工号'}],
        sex: [{required: true, message: '请选择性别'}],
        inFlag: [{required:true,message: '请选择用户类型'}],
        deptId: [{required: true, message: '请选择部门'}],
        email: [
          {required: true, message: '请输入邮箱'},
          {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur']},
        ],
      },
    }
  },
  mounted() {
    if (this.row) {
      // this.form = {...this.row};
      // this.form.deptId = parseInt(this.row.deptId);
      // this.form.bankList = this.row.userBankVos ? this.row.userBankVos : [],
      // this.password = this.row.password;
      this.getInfo(this.row.id);
    }
    this.getNoPageUser();
    this.getBankTo();

  },
  methods: {
    //检验银行卡号
    validatorNumber(rule,value,callback){
      let reg = /^[0-9]*$/;
      if(value === ''){
        callback(new Error('请输入银行卡号'));
      }else if(reg.test(value) && value.indexOf(' ') === -1){
        callback();
      }else {
        callback(new Error('无效的格式'))
      }
    },
    defaultChange(index, row) {
      this.form.bankList.forEach((f, Index) => {
        f.isDefault = false;
        if(Index === index){
          f.isDefault = true;
        }
      })
      // this.form.bankList[index].isDefault = true;
    },
    getBankTo() {
      companyService.getBankTo({}).then(resp => {
        this.bankTolList = resp.data;
      })
    },
    bankChange(val, index) {
      let obj = this.bankTolList.find(f => f.banktyp  === val);
      if (obj) {
        this.form.bankList[index].bank= obj.banknam
      }
    },
    getInfo(id){
      service.userInfo({id: id}).then(resp => {
        this.form.name = resp.data.name;
        this.form.deptId = resp.data.deptId;
        this.form.phone = resp.data.phone;
        this.form.email = resp.data.email;
        this.form.idCardNo = resp.data.idCardNo;
        this.form.haierCode = resp.data.haierCode;
        this.form.superiorId = resp.data.superiorId;
        if(resp.data.inFlag === false){
          this.form.inFlag = 0
        }else if(resp.data.inFlag === true){
          this.form.inFlag = 1
        }
        this.form.sex = resp.data.sex;
        this.form.bankList = resp.data.userBankVos ? resp.data.userBankVos : [];
      })
    },
    getNoPageUser(){
      service.listNoPage().then(resp => {
        this.userList = resp.data;
      })
    },
    save() {
      this.$refs['form'].validate(result => {
        if (result) {
          let deptName = this.deptList.find(f => this.form.deptId === f.id) && this.deptList.find(f => this.form.deptId === f.id).name || undefined
          let params = {
            ...this.form,
            userBankVos: this.form.bankList,
            deptName: deptName && deptName,
            password: this.isEdit ? undefined : this.password,
            id: this.isEdit ? this.row.id : undefined,
            haierCode: this.isEdit ? this.form.haierCode : this.form.haierCode,
          };
          if (this.isEdit) {
            service.saveUser({...params}).then(resp => {
              if(resp&& resp.message === '成功'){
                this.$message.success('修改成功');
                this.model_activated = false;
                this.$emit('close')
              } else {
                this.$message.error(resp.message);
              }
            })
          } else {
            service.saveUser({...params}).then(resp => {
              if(resp && resp.message === '成功'){
                this.$message.success('新增成功');
                this.model_activated = false;
                this.$emit('close')
              } else {
                this.$message.error(resp.message);
              }
            });
          }

        }
      })
    },
    //增加银行账号
    addBank() {
      this.form.bankList.push({
        bankSource: '',
        bankName: '',
        bankCode: '',
        cardNum: '',
        bankCategory : '',
        isDefault: this.form.bankList.length === 0,
      })
    },
    //删除银行账号
    delBankItem(item, index) {
      let list = [...this.form.bankList];
      list.splice(index, 1);
      this.form.bankList = list;
    },
    async findEnterpriseList(queryString, cb) {
      companyService.getBank({bankName: queryString}).then(res => {
        if (res) {
          this.selectEnterpriseList = res.data.list
        } else {
          this.selectEnterpriseList = []
        }
      })
    },
    async findEnterpriseCodeList(queryString, cb){
      companyService.getBank({bankBramchCode: queryString}).then(res => {
        if (res) {
          this.selectEnterpriseCodeList = res.data.list
        } else {
          this.selectEnterpriseCodeList = []
        }
      })
    },
    handleSelect(item, index) {
      let selectEnterpriseList = this.selectEnterpriseList;
      let thisBank = selectEnterpriseList.filter(obj => {
        return obj.bankName === item.bankName
      });
      if (thisBank) {
        this.form.bankList[index].bank = thisBank[0].bank;
        this.form.bankList[index].bankName = thisBank[0].bankName;
        this.form.bankList[index].bankCode = thisBank[0].bankBramchCode;
        // this.form.bankList[index].bankCategory = thisBank[0].bankCategory;
      }
    },
    handleCodeSelect(item, index, met){
      let selectEnterpriseList = this.selectEnterpriseCodeList;
      let thisBank = selectEnterpriseList.filter(obj => {
        return obj.bankBramchCode === item.bankCode
      });
      if (thisBank) {
        this.form.bankList[index].bank = thisBank[0].bank;
        this.form.bankList[index].bankName = thisBank[0].bankName;
        this.form.bankList[index].bankCode = thisBank[0].bankBramchCode;
        // this.form.bankList[index].bankCategory  = thisBank[0].bankCategory ;
      }
    },
  }
}
</script>

<style scoped>
.idCard-brief{
  font-size: 12px;
  color: rgb(245, 108, 108);
  position: absolute;
  top: 22px;
  margin-left: 106px;
}

</style>

<template>
  <div class="page-main flex flex-v">
    <el-form
        ref="searchForm"
        label-width="80px"
        size="mini"
    >
      <el-row>
        <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}">
          <el-form-item label="用户名">
            <el-input v-model="search_keys.name" clearable placeholder="用户名"/>
          </el-form-item>
        </el-col>
        <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}">
          <el-form-item label="部门">
            <tree-select
                noOptionsText="暂无数据"
                :options="deptList"
                v-model="search_keys.deptId"
                :disable-branch-nodes="true"
                :show-count="false"
                placeholder="请选择部门"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}">
          <el-form-item label="状态">
            <el-select v-model="search_keys.status" size="mini" clearable style="width: 100%">
              <el-option :value="1" :label="'启用'"></el-option>
              <el-option :value="0" :label="'禁用'"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="{span: 24}" :sm="{span: 24}" :md="{span: 8}" style="float: right">
          <el-form-item style="text-align: left" label-width="0">
            <el-button
                icon="el-icon-search"
                type="primary"
                size="mini"
                @click="executeSearch({search_keys, currentPage:1})"
            >查 询
            </el-button>
            <el-button
                icon="el-icon-plus"
                type="primary"
                plain
                size="mini"
                @click="addRow"
            >创 建
            </el-button>
            <el-button icon="el-icon-download" type="primary" size="mini" @click="downModule">模板下载</el-button>
            <el-upload
                style="position: absolute;right: 0;top:0"
                action="/api-base/import/importUserInUI"
                :headers="{'Authorization': $store.state.user.token}"
                :show-file-list="false"
                :on-success="onSuccessExe"
                :on-change="handleChange"
                :file-list="fileList">
              <el-button size="mini" icon="el-icon-upload2" type="primary" :loading="importLoading">批量导入</el-button>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="bg-white main-content-wrapper margin-10 flex flex-1 flex-v">
      <el-table
          header-cell-class-name="avue-crud"
          stripe
          class="flex-1"
          style="margin-top: 15px;"
          height="400px"
          v-loading="loading"
          size="mini"
          :data="search_result.list"
      >
        <el-table-column prop="name" label="姓名" align="center"  show-overflow-tooltip></el-table-column>
<!--        <el-table-column prop="idCardNo" label="身份证号" min-width="140" align="center" >-->
<!--          <template #default="scope">-->
<!--             <span>{{scope.row.idCardNo && scope.row.idCardNo.substring(0,6)+'********'+scope.row.idCardNo.substring(14, scope.row.idCardNo.length)}}</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="email" label="电子邮箱"  align="center"-->
<!--                         show-overflow-tooltip></el-table-column>-->
<!--        <el-table-column prop="phone" label="手机号" min-width="120" align="center" >-->
<!--          <template #default="scope">-->
<!--            <span>{{scope.row.phone && scope.row.phone.substring(0,3)+'****'+scope.row.phone.substring(7,11)}}</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="deptName" label="部门名称" min-width="120" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="inFlagName" label="用户类型" min-width="120" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="lastLoginDate" label="最近登陆时间" min-width="120" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" min-width="150" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="editRow(scope.row)" >编辑</el-button>
            <el-button type="text" size="mini" @click="stopAndUse(scope.row.id, 1)" v-if="scope.row.status == 0">启用</el-button>
            <el-button type="text" size="mini" @click="stopAndUse(scope.row.id, 0)" v-else>禁用</el-button>

           <el-popconfirm
               confirmButtonType="text"
               title="确定删除吗？"
               @confirm="removeRow(scope.row)"
           >
             <el-button slot="reference" type="text" size="mini">删除</el-button>
           </el-popconfirm>

            <el-button type="text" size="mini" @click="resetPsw(scope.row)">重置密码</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="width: 100%; padding-top: 20px;text-align: right" class="pagination">
        <el-pagination
            :total="search_result.total"
            :current-page="search_result.currentPage"
            :page-size="10"
            :page-sizes="[10, 20, 50, 100]"
            background
            layout="prev, pager, next, sizes, jumper, total"
            @current-change="executeSearch({currentPage:$event})"
            @size-change="executeSearch({currentPage:1,pageSize:$event})"
        />
      </div>
    </div>

    <add-or-edit-dialog v-bind.sync="dialogParams" v-if="dialogParams.activated"
                        @close="executeSearch({search_keys, currentPage:1})"></add-or-edit-dialog>

    <el-dialog title="错误详情" :visible.sync="dialogTableVisible" width="550px">
      <div style="margin-bottom:15px">{{ this.errInfo }}</div>
      <el-table :data="errorList" size="mini">
        <el-table-column label="错误信息" width="500" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import service from '@/api/department'
import loginService from '@/api/login'
import AddOrEditDialog from './addOrEditUserDialog'
import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import download from 'downloadjs'

export default {
  name: 'DomainManagement',
  components: {
    AddOrEditDialog,
    TreeSelect,
  },
  data() {
    return {
      importLoading: false,
      fileList: [],
      search_keys_snap: null,
      search_result: {currentPage: 1, pageSize: 10, list: []},
      loading: false,
      search_keys: {
        name: '',
        deptId: null,
        status: '',
        phone: '',
        idCardNo: '',
      },
      dialogParams: {activated: false},
      deptList: [],
      dialogTableVisible: false,
      errorList: [],
      errInfo: '',
    }
  },
  mounted() {
    this.executeSearch({search_keys: this.search_keys, currentPage: 1})
    this.getDept();
  },
  methods: {
    //模板下载
    downModule(){
      service.downModule().then(resp => {
        download((new Blob([resp])), '人员导入模板.xlsx');
      })
    },
    handleChange(file, fileList){
      this.importLoading = true;
      if(file.response){
        this.importLoading = false;
      }
    },
    //批量导入
    onSuccessExe(response, file){
      if(response.success){
        this.$message.success('上传成功')
        this.executeSearch({search_keys: this.search_keys, currentPage: 1})
      }else {
        this.$message.error(response.message);
      }
    },
    currentChange(e, f){
      console.log('e', e)
      console.log('f', f)
    },
    resetPsw(row){
      this.$confirm('密码将被重置为123456', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        loginService.resetPsw({id: row.id}).then(resp => {
          if(resp.success){
            this.$message.success('重置成功');
          }
        })
      });
    },
    executeSearch({
      currentPage = this.search_result.currentPage,
      pageSize = this.search_result.pageSize,
    }) {
      this.search_result.pageSize = pageSize;
      this.search_result.currentPage = currentPage;
      this.loading = true
      let params = {
        name: this.search_keys.name,
        deptId: this.search_keys.deptId,
        status: this.search_keys.status,
        phone: this.search_keys.phone,
        idCardNo: this.search_keys.idCardNo,
        currentPage,
        pageSize
      }
      service.getUserByDept({...params}).then(resp => {
        this.search_result.list = resp.data.data;
        this.search_result.total = resp.data.count;
      }).finally(() => {
        this.loading = false;
      });

    },

    //获取部门下拉
    getDept() {
      service.getDepartmentList().then(resp => {
        if (resp) {
          let list = service.getTree(resp.data);
          this.deptList = list;
        }
      })
    },

    // 新增
    addRow() {
      this.dialogParams = {activated: true, isEdit: false, deptList: this.deptList}
    },
    // 编辑
    editRow(row, flag) {
      this.dialogParams = {activated: true, isEdit: true, deptList: this.deptList, row: row}
    },
    //启用禁用
    stopAndUse(id,status) {
      service.stopAndUse({id: id, status: status}).then(resp => {
        if(resp.success){
          this.$message.success(status === 0 ? '禁用成功' : '启用成功')
          this.executeSearch({search_keys: this.search_keys, currentPage: 1})
        }
      })
    },
    //删除
    removeRow(row) {
      service.removeUser({id: row.id}).then(resp => {
        if (resp && resp.message === '成功') {
          this.$message.success('删除成功');
          this.executeSearch({search_keys: this.search_keys, currentPage: 1})
        }
      })
    },


    //导出
    exportFile() {
      let params = {
        searchInfo: this.search_keys.searchKeys || undefined,
        deptId: this.search_keys.deptId || undefined,
      };
      exportUser({...params}).then(resp => {
        download((new Blob([resp])), '用户管理.xlsx');
      });
    },
    handleExceed(files, fileList) {

    },
    onSuccess(response, file, fileList) {
      if (response.data && response.data.errorList.length > 0) {
        this.errorList = response.data.errorList;
        this.dialogTableVisible = true;
        this.errInfo = response.data.result;
      } else if (response.data && response.data.result) {
        this.$message.success(response.data.result)
      }
      this.fileList = [];
      this.executeSearch({search_keys: this.search_keys, currentPage: 1})
    }
  }
}
</script>

<style scoped lang="scss">
.page-main {
  height: 100%;
  background-color: #f6f6f6;
}

.icon {
  font-size: 16px;
  line-height: 0 !important;
}

::v-deep .el-pagination__total {
  float: right;
}

::v-deep .el-tooltip__popper {
  line-height: 0.5;
}

.el-form {
  background: #FFFFFF;
  padding: 20px 20px 0 20px;
  margin: 10px;

  .el-col {
    padding-left: 20px;
    padding-right: 20px;

    ::v-deep .el-form-item__label {
      font-size: 12px;
    }

    .el-input {
      width: 240px;
    }
  }
}
::v-deep .el-table__header-wrapper .el-checkbox {
     right: 4px;
  }
::v-deep .vue-treeselect__control{
  height: 28px;
 }
 ::v-deep .vue-treeselect__placeholder, .vue-treeselect__single-value{
   line-height: 28px;
   font-size: 12px;
  }
::v-deep .vue-treeselect__single-value{
  line-height: 28px !important;
  font-size: 12px !important;
}
::v-deep .vue-treeselect__menu-container{
  font-size: 12px !important;
}

</style>
